var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row justify-content-center" }, [
    _c("div", { staticClass: "col-md-12 col-sm-12" }, [
      _c(
        "div",
        {
          staticClass: "card shadow-lg border-0 rounded-lg mt-5 mx-auto",
          staticStyle: { width: "30rem" }
        },
        [
          _c(
            "h3",
            { staticClass: "card-header display-1 text-muted text-center" },
            [_vm._v(" 404 ")]
          ),
          _c(
            "span",
            { staticClass: "card-subtitle mb-2 text-muted text-center" },
            [_vm._v(" Page Could Not Be Found ")]
          ),
          _c(
            "div",
            { staticClass: "card-body mx-auto" },
            [
              _c("router-link", { attrs: { to: { name: "Home" } } }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary d-inline-block btn-lg",
                    attrs: { tag: "button", type: "submit" }
                  },
                  [_vm._v("Back To Home")]
                )
              ])
            ],
            1
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }